import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Content from "../components/content"
import Helmet from "react-helmet"

export const query = graphql`
  query ServiceData($slug: String!) {
    service: sanityServices(slug: { current: { eq: $slug } }) {
      title
      _rawDescription
      service_image {
        asset {
          url
        }
      }
    }
  }
`

const SingleServiceTemplate = props => {
  const { data } = props
  const service = data && data.service

  return (
    <Layout>
      <Helmet
        bodyAttributes={{
          class: "bg-power-lines",
        }}
      />
      <SEO title={`${service.title}`} />
      {/* <div>
        <img
          src={`${service.service_image.asset.url}?w=1440&h=800&fit=crop&crop=center`}
          alt={service.title}
          className="mb-4"
          style={{ width: "100%" }}
        />
      </div> */}
      <div className="py-24 w-full px-4 md:px-0 md:w-3/5 mx-auto">
        <div className="w-full text-right text-sm pb-6">
          <Link to="/services">&laquo; View All Services</Link>
        </div>
        <div className="border-b border-gray-800 mb-8 pb-8">
          <h1 className="uppercase font-bold tracking-wide mb-4 text-5xl">
            {service.title}
          </h1>
        </div>
        <article className="text-xl">
          <Content content={service._rawDescription} />
        </article>
      </div>
    </Layout>
  )
}

export default SingleServiceTemplate
